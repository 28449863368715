import { useQuery } from "react-query";
import pako from "pako";
import { Buffer } from "buffer";
import { getRsiV2 } from "api/quant";
import { IRsi } from "interfaces/quant.interface";
import LineGraphRsi from "components/graph/LineGraphRsi";
import { Progress } from "components/shared";

interface Props {
  code: string;
}

export default function RsiLineGraph({ code }: Props) {
  const { data, isLoading } = useQuery(["rsi", code], () => {
    return getRsiV2(code).then(({ data }) => {
      if (!data.data) return null;

      const buffer = Buffer.from(data.data.data, "base64");
      const byteArray = pako.ungzip(buffer);

      let str = "";

      for (let i = 0; i < byteArray.length; i++) {
        str += String.fromCharCode(byteArray[i]);
      }

      const rsi = JSON.parse(str) as IRsi;

      return rsi;
    });
  });

  if (isLoading) {
    return <Progress />;
  }

  if (!data) {
    return <div>제공된 데이터가 없습니다.</div>;
  }

  return (
    <div>
      <LineGraphRsi value={data} />
    </div>
  );
}
