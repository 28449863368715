import { IAssetsResult } from "interfaces/assets.interface";
import fetch from "./config";
import {
  IMetaResponse,
  IResponse,
  ISearchWithPagination,
} from "interfaces/common.interface";
import { IAllValue } from "interfaces/company.interface";
import {
  IQuantOrder,
  ICapParams,
  IExchange,
  ITechnicalDaily,
} from "interfaces/quant.interface";

// export const getYearlyMomentumV2 = (code: string) =>
//   fetch.get<IResponse<IMomentumV2 | null>>(`/quant/momentum/v2/${code}`);

export const getYearlyMomentumV3 = (code: string) =>
  fetch.get<IResponse<{ data: string } | null>>(`/quant/momentum/v3/${code}`);

export const getRsiV2 = (code: string) =>
  fetch.get<IResponse<{ data: string } | null>>(`/quant/rsi/v2/${code}`);

export const getOilStock = () =>
  fetch.get<IResponse<{ data: string } | null>>(`/quant/oil-stock`);

export const getQuant = () => fetch.get<IResponse<IAllValue[]>>(`/quant`);

export const getTechnical = () =>
  fetch.get<
    IResponse<{
      [key in
        | "ror"
        | "monthRor"
        | "yearRor"
        | "rsiTop"
        | "rsiBottom"
        | "newHighPrice"]: ITechnicalDaily[];
    }>
  >(`/quant/technical`);

export const getCapQuant = (params: ICapParams) =>
  fetch.get<IResponse<IQuantOrder[]>>(`/quant/orderby`, { params });

export const getExchange = () =>
  fetch.get<IResponse<IExchange[]>>(`/quant/exchange`);

export const getAssets = (params: ISearchWithPagination) =>
  fetch.get<IMetaResponse<IAssetsResult[]>>(`/assets`, { params });

export const updateAssets = (id: number, roe: number) =>
  fetch.put<IResponse>(`/assets/${id}`, { roe });

export const postRequestExchange = () => fetch.post(`/quant/exchange`);

export const getCsv = (code: string) =>
  fetch.get<IResponse<string>>(`/quant/csv/${code}`);
