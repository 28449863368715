import "styles/ui/checkbox.css";
import Icon from "./Icon";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const Checkbox = ({ label, ...props }: IProps) => {
  return (
    <label className="ui-checkbox text-sm text-neutral-700 flex items-center select-none cursor-pointer">
      <input {...props} type="checkbox" />
      <div className="ui-checkbox-box">
        <Icon icon="check" fontSize={12} />
      </div>
      <span className="ml-2 ui-checkbox-label">{label}</span>
    </label>
  );
};

export default Checkbox;
