import {
  faAdd,
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faArrowDown,
  faArrowLeft,
  faArrowRightFromBracket,
  faArrowUp,
  faArrowUpRightFromSquare,
  faArrowRotateLeft,
  faBars,
  faCalculator,
  faCaretDown,
  faCaretUp,
  faCheck,
  faDatabase,
  faDollarSign,
  faEarth,
  faEllipsisVertical,
  faEye,
  faImage,
  faList,
  faMinus,
  faPaperclip,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPercent,
  faStar,
  faSpinner,
  faTrashAlt,
  faThumbTack,
  faUser,
  faUserCircle,
  faWindowRestore,
  faXmark,
  faDownload,
  faFileExcel,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

import {
  faCopy,
  faEdit,
  faTrashAlt as farTrashAlt,
} from "@fortawesome/free-regular-svg-icons";

import { library } from "@fortawesome/fontawesome-svg-core";

library.add(
  faAdd,
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
  faArrowDown,
  faArrowLeft,
  faArrowRightFromBracket,
  faArrowUp,
  faArrowUpRightFromSquare,
  faArrowRotateLeft,
  faBars,
  faCalculator,
  faCaretDown,
  faCaretUp,
  faCheck,
  faCopy,
  faDownload,
  faDatabase,
  faDollarSign,
  faEarth,
  faEdit,
  faEllipsisVertical,
  faEye,
  faFileExcel,
  faImage,
  faList,
  faMinus,
  faPaperclip,
  faPaperPlane,
  faPen,
  faPenToSquare,
  faPercent,
  farTrashAlt,
  faThumbTack,
  faStar,
  faSpinner,
  faTrashAlt,
  faUser,
  faUserCircle,
  faWindowRestore,
  faQuestion,
  faXmark
);
