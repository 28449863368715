import React, { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { getTargetPrice } from "api/company";
import { Pagination, Progress } from "components/shared";
import { comma } from "utils/lib";
import { classNameJoin, nl2br } from "utils/ui.lib";
import { useRecoilValue } from "recoil";
import { userIdState } from "store/user";
import TargetPriceGraph from "components/graph/TargetPriceGraph";

interface Props {
  code: string;
  tclp: string;
}

const TargetPrice = ({ code, tclp }: Props) => {
  const [expanded, setExpanded] = useState<number[]>([]);
  const [page, setPage] = useState<number>(1);
  const userId = useRecoilValue(userIdState);

  const { data, isLoading, isError } = useQuery(
    ["target-price", code, page, userId],
    async () => {
      if (!code || userId === -1) return null;

      const { data } = await getTargetPrice({
        search: code,
        category: "code",
        page,
        limit: 10,
      });

      if (!data.data) return null;

      const items = data.data.data;

      return {
        items: items.map(({ comment, registed_at, ...rest }) => {
          return {
            comment: nl2br(comment),
            registed_at: dayjs(registed_at).format("YYYY-MM-DD"),
            ...rest,
          };
        }),
        avg: data.data.avg,
        total: data.meta.total,
      };
    }
  );

  const expandedHandler = (id: number) => {
    if (expanded.includes(id)) {
      setExpanded((prevState) => {
        const next = prevState.filter((d) => d !== id);
        return next;
      });
    } else {
      setExpanded([...expanded, id]);
    }
  };

  /**
   * 등락률 계산
   */
  const calcRate = useCallback(
    (tragetprice: string | number) => {
      if (!tragetprice) return "";

      const current = parseInt(tclp);
      const diff = parseInt(tragetprice.toString()) - current;
      const share = current / 100;
      return parseFloat((diff / share).toFixed(2));
    },
    [tclp]
  );

  const upperRate = useMemo(() => {
    if (!data || data.avg === 0) return 0;

    return calcRate(data.avg);
  }, [data, calcRate]);

  return (
    <div className="py-8">
      <h5 className="flex font-bold text-lg mr-2 justify-between items-center">
        <div>
          <span>목표주가</span>
          {isLoading && <Progress size={12} />}
        </div>
      </h5>

      {isError || !data || data.items.length === 0 ? (
        <div className="text-center py-8">목표주가가 없습니다.</div>
      ) : (
        <div className="py-4">
          <div className="mb-4">
            <div className="text-lg">평균가격 {comma(data.avg)} 원</div>
            <div className="text-sm">현재가격 {comma(tclp)} 원</div>
            <div className="text-sm text-emerald-500">
              상승여력 {upperRate}%
            </div>
          </div>
          <TargetPriceGraph
            data={data.items}
            price={{ avg: data.avg, current: tclp }}
          />
        </div>
      )}
    </div>
  );
};

export default TargetPrice;

//   <table className="values-table">
//     <thead>
//       <tr>
//         <td>날짜</td>
//         <td>가격</td>
//         <td>내용</td>
//         <td>제공</td>
//         <td>작성자</td>
//       </tr>
//     </thead>
//     <tbody>
//       {data.items.map(
//         ({
//           id,
//           author,
//           registed_at,
//           price,
//           provider,
//           comment,
//           title,
//         }) => {
//           return (
//             <React.Fragment key={id}>
//               <tr
//                 className="cursor-pointer"
//                 onClick={() => expandedHandler(id)}
//               >
//                 <td>{registed_at}</td>
//                 <td className="font-bold">
//                   <span>{comma(price)}원</span>
//                   <span className="text-xs ml-2">
//                     ({calcRate(price)}%)
//                   </span>
//                 </td>
//                 <td>
//                   <div className="text-left font-bold">{title}</div>
//                 </td>
//                 <td>{provider}</td>
//                 <td>{author}</td>
//               </tr>
//               <tr
//                 className={classNameJoin([
//                   expanded.includes(id) ? "visible" : "hidden",
//                 ])}
//               >
//                 <td colSpan={5}>
//                   <div
//                     className="text-left py-2"
//                     dangerouslySetInnerHTML={{ __html: comment }}
//                   />
//                 </td>
//               </tr>
//             </React.Fragment>
//           );
//         }
//       )}
//     </tbody>
//   </table>
