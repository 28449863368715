import { useState } from "react";
import { OrderBy } from "components/shared/tool/Sortable";

interface IProps {
  [key: string]: OrderBy;
}

type Handler = (target: string, orderBy: OrderBy) => void;

const useOrderBy = (defaultValue: IProps = {}): [IProps, Handler] => {
  const [orderBy, setOrderBy] = useState<IProps>(defaultValue);

  const orderByHandler = (target: string, orderBy: OrderBy) => {
    setOrderBy((prevState) => {
      const next = { ...prevState, [target]: orderBy };
      return next;
    });
  };

  return [orderBy, orderByHandler];
};

export default useOrderBy;
