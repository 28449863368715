import * as d3 from "d3";
import { Frame } from "interfaces/common.interface";
import { ITargetPrice } from "interfaces/dart.interface";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

type D3_SVGElement = d3.Selection<SVGSVGElement, unknown, null, any>;

interface Props {
  data: ITargetPrice[];
  price: {
    avg: string | number;
    current: string | number;
  };
}

const MARGIN = {
  top: 20,
  bottom: 20,
  left: 20,
  right: 20,
};

export default function TargetPriceGraph({ data, price }: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const svg = useRef<D3_SVGElement | null>(null);

  const [frame, setFrame] = useState<Frame>({ width: 0, height: 0 });

  // DATA
  const _data = useMemo(() => {
    const yPrice: number[] = [];
    const xDate: Date[] = [];

    for (let i = 0; i < data.length; i++) {
      const { price, registed_at } = data[i];
      yPrice.push(isNaN(parseInt(price)) ? 0 : parseInt(price));
      xDate.push(new Date(registed_at));
    }

    return {
      x: xDate,
      y: yPrice,
    };
  }, [data]);

  // EVENT
  const resize = () => {
    if (!ref.current) return;
    const width = ref.current.getBoundingClientRect().width;
    const height = Math.floor((width / 16) * 5.5);

    setFrame((prevState) => {
      if (prevState.width !== width || prevState.height !== height) {
        return { width, height };
      }

      return prevState;
    });
  };

  const drawLeftAxis = () => {};

  const drawBottomAxis = useCallback((frame: Frame, data: typeof _data) => {
    if (!svg.current) return;

    const width = frame.width - MARGIN.left - MARGIN.right;
    const xScale = d3
      .scaleBand<Date>()
      .domain(_data.x)
      .paddingOuter(0)
      .paddingInner(1)
      .range([0, width]);

    const g = svg.current
      .append("g")
      .attr(
        "transform",
        `translate(${MARGIN.left}, ${frame.height - MARGIN.bottom})`
      );

    g.call(d3.axisBottom<Date>(xScale).tickFormat(d3.utcFormat("%Y %m %d")));
  }, []);

  const draw = useCallback(() => {
    if (!svg.current) return;
    const { width, height } = frame;
    svg.current.select("svg").attr("width", width).attr("height", height);

    drawBottomAxis(frame, _data);
  }, [frame, _data, drawBottomAxis]);

  useEffect(() => {
    draw();
  }, [draw, frame]);

  useEffect(() => {
    if (!ref.current) return;
    if (svg.current) svg.current.remove();

    const width = ref.current.getBoundingClientRect().width;
    const height = Math.floor((width / 16) * 5.5);

    setFrame({ width, height });

    svg.current = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    draw();

    window.addEventListener("resize", resize);

    return function cleanup() {
      console.log("cleanup");
      window.removeEventListener("resize", resize);
    };
  }, []);

  return <div ref={ref}></div>;
}
