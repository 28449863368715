import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import pako from "pako";
import { Buffer } from "buffer";
import { getYearlyMomentumV3 } from "api/quant";
import StockBarGraph from "components/graph/StockBarGraph";
import { IMomentum, IMomentumData } from "interfaces/quant.interface";

interface Props {
  code?: string;
}

export default function MomentumBarGraph({ code }: Props) {
  const params = useParams();

  const { data } = useQuery(["momentum-v3", code, params], () => {
    const _code = code || params.code;
    if (!_code) return null;
    return getYearlyMomentumV3(_code).then(({ data }) => {
      if (!data.data) return null;

      const buffer = Buffer.from(data.data.data, "base64");
      const byteArray = pako.ungzip(buffer);

      const result: IMomentumData[] = [];

      let str = "";

      for (let i = 0; i < byteArray.length; i++) {
        str += String.fromCharCode(byteArray[i]);
      }

      const momentum = JSON.parse(str) as IMomentum;
      // const length = Object.keys(momentum.Close).length;

      for (let date in momentum.Close) {
        const d = dayjs(parseInt(date));

        const max = momentum.High[date];
        const min = momentum.Low[date];

        result.push({
          Year: d.year(),
          Month: d.month(),
          Max: max,
          Min: min,
          Average: (max + min) / 2,
          Close: momentum.Close[date],
          Open: momentum.Open[date],
          EndDate: d.format("YYYY-MM-DD"),
          StartDate: d.format("YYYY-MM-DD"),
        });
      }

      return {
        data: result,
      };
    });
  });

  if (!data || !data.data) return null;

  return (
    <div className="pt-8">
      <div>
        <h5 className="inline-block font-bold text-lg">12개월 모멘텀</h5>
        <div className="text-sm space-x-2 text-neutral-500">
          <span>마지막 업데이트.</span>
          {/* <span>{data.created_at}</span> */}
        </div>
      </div>

      <StockBarGraph data={data.data} />
    </div>
  );
}
