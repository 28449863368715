import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as d3 from "d3";
import { IMomentumData } from "interfaces/quant.interface";
import dayjs from "dayjs";

type D3_SVGElement = d3.Selection<SVGSVGElement, unknown, null, any>;

const CONFIG = {
  height: 350,
  margin: { top: 30, left: 50, right: 20, bottom: 40 },
  color: {
    up: "#e00400",
    down: "#003ace",
  },
};

interface Props {
  data: IMomentumData[];
  margin?: typeof CONFIG.margin;
}

export default function StockBarGraph({ data, margin = CONFIG.margin }: Props) {
  const [width, setWidth] = useState<number>(0);

  const graphRef = useRef<HTMLDivElement | null>(null);
  const svgRef = useRef<D3_SVGElement | null>(null);

  /**
   * Bar color
   */
  const getColor = (type: string) => {
    return type === "down" ? CONFIG.color.down : CONFIG.color.up;
  };

  /**
   * MIN, MAX
   */
  const { MIN, MAX } = useMemo(() => {
    const MIN = d3.min(data.map(({ Min }) => Min)) || 0;
    const MAX = d3.max(data.map(({ Max }) => Max)) || 0;
    const sub = MAX - MIN;
    const MIN_SPACE = sub / 20; // max 와 min 의 공간을 생성시켜줄 크기
    const MAX_SPACE = sub / 10; // max 와 min 의 공간을 생성시켜줄 크기

    return {
      MIN: MIN - MIN_SPACE < 0 ? 0 : MIN - MIN_SPACE,
      MAX: MAX + MAX_SPACE,
    };
  }, [data]);

  /**
   * Bar Scale
   */
  const xScale = useMemo(
    () =>
      d3
        .scaleBand<number>()
        .domain(d3.range(data.length))
        .rangeRound([0, width - margin.left - margin.right])
        .padding(0.5),
    [width, data, margin]
  );

  /**
   * y Axis Scale
   */
  const yScale = useMemo(
    () =>
      d3
        .scaleLinear<number>()
        .domain([MAX, MIN < 0 ? 0 : MIN])
        .range([0, CONFIG.height - margin.top - margin.bottom]),
    [MAX, MIN, margin]
  );

  /**
   * 데이터 초기화
   */
  const _data = useMemo(() => {
    return data.map(({ Open, Close, ...rest }) => {
      const d = Open > Close ? "down" : "up";

      return {
        Open,
        Close,
        Direction: d,
        MaxValue: d === "down" ? Open : Close,
        MinValue: d === "up" ? Open : Close,
        Value:
          yScale(d === "down" ? Close : Open) -
          yScale(d === "down" ? Open : Close),
        ...rest,
      };
    });
  }, [data, yScale]);

  /**
   * x Axis Scale (Date)
   */

  const xDateScale = useMemo(
    () =>
      d3
        .scaleBand<string>()
        .domain(
          _data.map(({ Year, Month }) =>
            d3.timeFormat("%Y-%m")(dayjs(`${Year}-${Month + 1}`).toDate())
          )
        )
        .range([0, width - margin.left - margin.right])
        .padding(0.5),
    [width, margin, _data]
  );

  /**
   * x축, y축 그리기
   */
  const axis = useCallback(() => {
    if (!svgRef.current) return;

    const tx = margin.left;
    const ty = CONFIG.height - margin.bottom;

    // xAxis
    svgRef.current
      .append("g")
      .classed("x-axis", true)
      .attr("transform", `translate(${tx}, ${ty})`)
      .call(d3.axisBottom<string>(xDateScale))
      .selectAll("text")
      .attr("dx", "-1em")
      .attr("dy", "1em")
      .attr("transform", "rotate(-35)");

    // yAxis
    svgRef.current
      .append("g")
      .classed("y-axis", true)
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .call(d3.axisLeft(yScale));
  }, [xDateScale, yScale, margin]);

  /**
   * x 축 Bar 그리기
   */
  const bar = useCallback(() => {
    if (!svgRef.current) return;

    const g = svgRef.current.selectAll("rect").data(_data).enter();

    // MAX to TopValue
    g.append("rect")
      .attr(
        "x",
        (_, i) => (xScale(i) || 0) + xScale.bandwidth() / 2 - 0.5 + margin.left
      )
      .attr("y", (d) => yScale(d.Max) + margin.top)
      .attr("width", 1)
      .attr("height", (d) => yScale(d.MaxValue) - yScale(d.Max))
      .attr("fill", (d) => getColor(d.Direction));

    // TopValue to BottomValue
    g.append("rect")
      .attr("x", (_, i) => (xScale(i) || 0) + margin.left)
      .attr(
        "y",
        (d) => yScale(d.Direction === "down" ? d.Open : d.Close) + margin.top
      )
      .attr("width", xScale.bandwidth())
      .attr("height", (d) => d.Value)
      .attr("fill", (d) => getColor(d.Direction));

    // BottomValue to MIN
    g.append("rect")
      .attr(
        "x",
        (_, i) => (xScale(i) || 0) + xScale.bandwidth() / 2 - 0.5 + margin.left
      )
      .attr("y", (d) => yScale(d.MinValue) + margin.top)
      .attr("width", 1)
      .attr("height", (d) => yScale(d.Min) - yScale(d.MinValue))
      .attr("fill", (d) => getColor(d.Direction));
  }, [_data, margin, xScale, yScale]);

  /**
   * 초기화
   */
  const init = useCallback(
    (width: number) => {
      if (!graphRef.current) return;

      // 그려진 chart가 있으면 제거한다.
      if (svgRef.current) svgRef.current.remove();

      svgRef.current = d3
        .select(graphRef.current)
        .append("svg")
        .attr("width", width)
        .attr("height", CONFIG.height);

      axis();
      bar();
    },
    [axis, bar]
  );

  useEffect(() => {
    // 초기 넓이를 세팅해준다.
    if (graphRef.current) {
      const { clientWidth } = graphRef.current;
      setWidth(clientWidth);
      init(clientWidth);
    }
  }, [init]);

  return <div ref={graphRef}></div>;
}
