import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as d3 from "d3";
import { IRsi } from "interfaces/quant.interface";
import dayjs from "dayjs";

type D3_SVGElement = d3.Selection<SVGSVGElement, unknown, null, any>;

interface Props {
  id?: string;
  value: IRsi;
  margin?: typeof CONFIG.margin;
}

const height = 150;
const CONFIG = {
  margin: { top: 20, left: 50, right: 20, bottom: 40 },
};

export default function LineGraphRsi({
  id = "rsi-chart",
  value,
  margin = CONFIG.margin,
}: Props) {
  const ref = useRef<HTMLDivElement | null>(null);
  const svg = useRef<D3_SVGElement | null>(null);

  const [width, setWidth] = useState(0);

  const length = useMemo(() => value.date.length, [value]);

  const xDateScale = useMemo(
    () =>
      d3
        .scaleBand<string>()
        .domain(
          value.date.map((d) => d3.timeFormat("%Y-%m")(dayjs(d).toDate()))
        )
        .range([0, width - margin.left - margin.right]),
    [value, width, margin]
  );

  const xScale = useMemo(
    () =>
      d3
        .scaleBand<number>()
        .domain(d3.range(length))
        .range([0, width - margin.left - margin.right]),
    [length, width, margin]
  );

  const yScale = useMemo(
    () =>
      d3
        .scaleLinear<number>()
        .domain([100, 0])
        .range([0, height - margin.top - margin.bottom]),
    [margin]
  );

  const mouseover = () => {};
  const mouseout = () => {};

  const fixedLine = useCallback(
    (y: number, color = "black") => {
      if (!svg.current) return;

      const g = svg.current;

      const pathGenerator = d3
        .line()
        .x((_, i) => (xScale(i) || 1) + margin.left)
        .y(yScale(y) + margin.top);

      g.append("path")
        .attr("fill", "none")
        .attr("stroke", color)
        .attr("stroke-width", 0.5)
        .attr("stroke-dasharray", "5,2")
        .attr("d", pathGenerator(new Array(length)));
    },
    [length, margin, xScale, yScale]
  );

  // 그래프 설정
  const line = useCallback(
    (data: (number | null)[], color: string) => {
      if (!svg.current) return;

      const pathGenerator = d3
        .line<any>()
        .defined((d) => d !== null)
        .x((_, i) => (xScale(i) || 1) + margin.left)
        .y((d) => yScale(d) + margin.top);

      const g = svg
        .current!.on("mouseover", mouseover)
        .on("mouseout", mouseout);

      g.append("path")
        .attr("fill", "none")
        .attr("stroke", color)
        .attr("stroke-width", 1)
        .attr("d", pathGenerator(data));
    },
    [xScale, yScale, margin]
  );

  // 축 설정
  const axis = useCallback(() => {
    if (!svg.current) return;

    const tx = margin.left;
    const ty = height - margin.bottom;

    svg.current
      .append("g")
      .classed("x-axis", true)
      .attr("transform", `translate(${tx}, ${ty})`)
      .call(d3.axisBottom<string>(xDateScale))
      .selectAll("text")
      .attr("dx", "-1em")
      .attr("dy", "1em")
      .attr("transform", "rotate(-35)");

    // yAxis
    svg.current
      .append("g")
      .classed("y-axis", true)
      .attr("transform", `translate(${margin.left}, ${margin.top})`)
      .call(d3.axisLeft(yScale));
  }, [xDateScale, yScale, margin]);

  // 라벨링
  const label = useCallback(() => {
    if (!svg.current) return;

    const g = svg.current
      .append("g")
      .selectAll(".label")
      .data(["RSI", "RSI Signal"])
      .enter();

    const item = g.append("g");

    const rect = item
      .append("rect")
      .attr("width", 8)
      .attr("height", 8)
      .attr("fill", (d) => {
        return d === "RSI" ? "#989898" : "#fb8f21";
      })
      .attr("y", -8);

    const text = item
      .append("text")
      .attr("text-anchor", "end")
      .attr("font-size", 11)
      .text((label) => label);

    rect.nodes().forEach((node, i) => {
      const textNodes = text.nodes();
      const textRect = textNodes[i].getBoundingClientRect();
      const margin = 4;
      node.setAttribute("x", `-${textRect.width + 8 + margin}`);
    });

    item.attr("transform", function () {
      const prevLabel = this.previousSibling;

      if (prevLabel) {
        const marginRight = 10;
        const rect = (prevLabel as HTMLElement).getBoundingClientRect();

        return `translate(${width - margin.right - marginRight - rect.width}, ${
          margin.top
        })`;
      }

      return `translate(${width - margin.right}, ${margin.top})`;
    });
  }, [margin, width]);

  // 초기화
  const init = useCallback(
    (width: number) => {
      if (!ref.current) return;
      if (svg.current) svg.current.remove();

      svg.current = d3
        .select(ref.current)
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      axis();
      label();

      fixedLine(70, "#ee3738");
      fixedLine(30, "#0d7df3");

      line(value.rsi, "#989898");
      line(value.rsi_signal, "#fb8f21");
    },
    [axis, line, value, fixedLine, label]
  );

  useEffect(() => {
    if (!ref.current) return;
    const clientWidth = ref.current.getBoundingClientRect().width;

    setWidth(clientWidth);
    init(clientWidth);
  }, [init]);

  return <div id={id} ref={ref}></div>;
}
