import { useMemo, useState } from "react";
import { classNameJoin } from "utils/ui.lib";
import Input from "./Input";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  data?: { [key in string]: any }[];
  render?: (props: any, index: number) => React.PropsWithChildren<any>;
  onEnter?: (ev: React.KeyboardEvent<HTMLInputElement>) => void;
  onSelect?: (item: any) => void;
}

const ComboBox = ({
  data = [],
  render,
  className,
  onEnter,
  onSelect,
  ...props
}: Props) => {
  const [toggle, setToggle] = useState<boolean>(false);

  const selectHandler = (ev: React.MouseEvent<HTMLLIElement>, item: any) => {
    ev.preventDefault();
    onSelect && onSelect(item);
  };

  const focusHandler = () => {
    setToggle(true);
  };

  const blurHandler = () => {
    setToggle(false);
  };

  const show = useMemo(() => {
    if (data.length === 0) return false;
    return toggle;
  }, [toggle, data]);

  return (
    <div className={classNameJoin(["block w-full h-9 relative", className])}>
      <div>
        <Input
          {...props}
          onEnter={onEnter}
          onFocus={focusHandler}
          onBlur={blurHandler}
        />
      </div>
      <div
        className={classNameJoin([
          "absolute top-[100%] left-0 right-0 mt-1 max-h-[400px] overflow-auto shadow-custom rounded transition-all duration-300 z-40",
          show
            ? "visible translate-y-0 opacity-1"
            : "invisible translate-y-6 opacity-0",
        ])}
      >
        <ul className="bg-white py-2">
          {data &&
            data.map((item, index) => {
              return (
                <li
                  className="text-sm py-2 px-5 cursor-pointer hover:bg-slate-100"
                  key={index}
                  onClick={(ev) => selectHandler(ev, item)}
                >
                  {render ? render(item, index) : item}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default ComboBox;
